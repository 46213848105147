@font-face {
  font-family: "Gerbera";
  src: url("../fonts/Gerbera.eot");
  src: url("../fonts/Gerbera.eot?#iefix") format("embedded-opentype"), url("../fonts/Gerbera.woff") format("woff"), url("../fonts/Gerbera.ttf") format("truetype");
  font-style: normal;
  font-weight: 400; }

@font-face {
  font-family: "Gerbera";
  src: url("../fonts/Gerbera-Light.eot");
  src: url("../fonts/Gerbera-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Gerbera-Light.woff") format("woff"), url("../fonts/Gerbera-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: "Gerbera";
  src: url("../fonts/Gerbera-Bold.eot");
  src: url("../fonts/Gerbera-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Gerbera-Bold.woff") format("woff"), url("../fonts/Gerbera-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 600; }

@font-face {
  font-family: "Gerbera";
  src: url("../fonts/Gerbera-Black.eot");
  src: url("../fonts/Gerbera-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/Gerbera-Black.woff") format("woff"), url("../fonts/Gerbera-Black.ttf") format("truetype");
  font-style: normal;
  font-weight: 900; }

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #5f5f5f;
  opacity: 1;
  /* Firefox */ }

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #5f5f5f; }

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #5f5f5f; }

.modal ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fff;
  opacity: 1;
  /* Firefox */ }

.modal :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff; }

.modal ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #fff; }

html {
  font: 400 18px/1.25 "Gerbera", sans-serif;
  color: #5f5f5f;
  background: #fff; }
  html.active {
    overflow: hidden; }

* {
  box-sizing: border-box; }

input {
  outline: none;
  color: #0d0d0d; }

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle; }

nav ul {
  margin: 0;
  padding: 0; }
  nav ul li {
    list-style: none; }
    nav ul li a {
      text-decoration: none; }

b, strong {
  font-weight: bold; }

h2 {
  font: 900 32px/1.25 "Gerbera", sans-serif;
  color: #0d0d0d;
  text-align: left;
  position: relative;
  max-width: 18em;
  margin: 1em auto;
  display: inline-block; }
  @media (min-width: 768px) {
    h2 {
      font-size: 40px; } }
  @media (min-width: 1200px) {
    h2 {
      font-size: 71px;
      margin: 54px auto 1.85em; } }
  h2.left-line:after, h2.right-line:after {
    content: "";
    position: absolute;
    width: 4.6em;
    top: 25px;
    height: 3px;
    background: #cccccc; }
    @media (min-width: 1200px) {
      h2.left-line:after, h2.right-line:after {
        top: 45px; } }
  h2.left-line:after {
    right: 115%; }
  h2.right-line:after {
    left: 115%; }

.text-center {
  text-align: center;
  overflow: hidden; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.btn {
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
  border: none;
  outline: none;
  cursor: pointer;
  letter-spacing: 0.7px;
  color: #0d0d0d;
  font-family: "Gerbera", sans-serif;
  border-radius: 32px;
  transition: all .15s ease;
  font-size: 20px;
  font-weight: 900;
  height: 45px;
  line-height: 40px;
  display: inline-block;
  padding: 0 15px;
  min-width: 250px; }
  @media (min-width: 768px) {
    .btn {
      min-width: 285px;
      height: 64px;
      line-height: 60px;
      font-size: 24px; } }
  @media (min-width: 992px) {
    .btn-lg {
      min-width: 350px; } }
  .btn-primary {
    background: #08cbd7; }
    .btn-primary:hover {
      background: #09e2f0; }

.hero input {
  border-radius: 31.5px;
  border: solid 3px #c4c4c4;
  background-color: #e9e9e9;
  padding: 0 28px;
  font-size: 16px;
  color: #5f5f5f;
  height: 45px;
  width: 250px;
  margin-bottom: 10px; }
  @media (min-width: 768px) {
    .hero input {
      height: 63px;
      width: 269px;
      margin-right: 9px;
      font-size: 20px; } }

.modal input {
  height: 60px;
  border-radius: 31.5px;
  border: 3px solid #c4c4c4;
  background-color: #e9e9e9;
  padding: 0 28px;
  font-size: 20px;
  margin-right: 9px;
  color: #5f5f5f;
  width: 100%;
  margin-bottom: 17px; }

.authForm__inp {
  position: relative;
  margin-bottom: 15px; }

label.error {
  position: absolute;
  bottom: -7px;
  left: 30px;
  font-size: 13px;
  color: red; }

input.error {
  border-color: red !important; }

.container {
  max-width: 1630px;
  margin: 0 auto;
  padding: 0 15px; }

.hero {
  padding: 14px 0;
  background: url("../img/hero-bg.jpg");
  background-size: cover; }
  @media (min-width: 768px) {
    .hero {
      min-height: 32em;
      padding-bottom: 100px; } }
  @media (min-width: 992px) {
    .hero {
      padding-bottom: 150px; } }
  @media (min-width: 1600px) {
    .hero {
      padding-bottom: 285px; } }
  .hero .header-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 40px; }
    @media (min-width: 768px) {
      .hero .header-inner {
        padding-right: 0; } }
  .hero .header__logo {
    position: relative;
    z-index: 22; }
    @media (min-width: 1600px) {
      .hero .header__logo {
        margin-right: 190px; } }
    .hero .header__logo span {
      font: 600 25px/1 "Gerbera", sans-serif;
      color: #465ce4; }
      @media (min-width: 768px) {
        .hero .header__logo span {
          font-size: 48px; } }
  .hero .header__nav {
    align-items: center;
    display: none; }
    @media (min-width: 1200px) {
      .hero .header__nav {
        margin-right: 88px;
        margin-top: 6px; } }
    @media (min-width: 768px) {
      .hero .header__nav .submenu-icon {
        display: none; } }
    .hero .header__nav.active {
      position: fixed;
      height: 100%;
      width: 100%;
      background: #000;
      z-index: 15;
      top: 0;
      left: 0;
      display: block;
      padding-top: 75px;
      padding-left: 15px; }
      .hero .header__nav.active ul:not(.submenu) {
        overflow-y: auto;
        max-height: 100%; }
        .hero .header__nav.active ul:not(.submenu) > li {
          display: block;
          width: calc(100% - 15px);
          border-bottom: 1px solid #474747;
          padding: 18px 0;
          position: relative; }
          .hero .header__nav.active ul:not(.submenu) > li:last-child {
            border-bottom: none; }
          .hero .header__nav.active ul:not(.submenu) > li > a {
            color: #fff;
            font-size: 24px; }
            .hero .header__nav.active ul:not(.submenu) > li > a .submenu-icon {
              position: absolute;
              width: 20px;
              height: 20px;
              right: 5px;
              top: 25px;
              transition: all .15s ease-in-out; }
              .hero .header__nav.active ul:not(.submenu) > li > a .submenu-icon.active {
                transform: rotate(180deg); }
              .hero .header__nav.active ul:not(.submenu) > li > a .submenu-icon i {
                margin-left: 4px;
                border-color: #fff; }
          .hero .header__nav.active ul:not(.submenu) > li .submenu {
            display: none; }
            .hero .header__nav.active ul:not(.submenu) > li .submenu.active {
              display: block;
              margin-top: 15px; }
              .hero .header__nav.active ul:not(.submenu) > li .submenu.active a {
                color: #5f5f5f;
                display: inline-block;
                padding: 8px 0; }
                .hero .header__nav.active ul:not(.submenu) > li .submenu.active a:hover {
                  color: #fff; }
    @media (min-width: 768px) {
      .hero .header__nav {
        display: flex; }
        .hero .header__nav ul:not(.submenu) > li {
          position: relative;
          padding: 18px 0 22px 0;
          display: inline-block; }
          .hero .header__nav ul:not(.submenu) > li > a {
            font: 300 20px/1 "Gerbera", sans-serif;
            color: #474747;
            margin-right: 15px;
            padding-bottom: 5px;
            letter-spacing: .5px; } }
      @media (min-width: 768px) and (min-width: 768px) {
        .hero .header__nav ul:not(.submenu) > li > a {
          position: relative; } }
      @media (min-width: 768px) and (min-width: 1200px) {
        .hero .header__nav ul:not(.submenu) > li > a {
          margin-right: 38px;
          font-size: 24px; } }
    @media (min-width: 768px) {
            .hero .header__nav ul:not(.submenu) > li > a:before {
              content: '';
              position: absolute;
              width: 100%;
              height: 3px;
              background: #08cbd7;
              bottom: 0;
              left: 0;
              transition: all .15s ease;
              opacity: 0; }
            .hero .header__nav ul:not(.submenu) > li > a:hover:before {
              opacity: 1; } }
    @media (min-width: 768px) and (min-width: 768px) {
      .hero .header__nav ul:not(.submenu) > li .submenu {
        display: none;
        position: absolute;
        background: #000;
        padding: 18px 20px 8px;
        border-radius: 5px;
        top: 100%;
        min-width: 195px;
        z-index: 19; }
        .hero .header__nav ul:not(.submenu) > li .submenu a {
          color: #5f5f5f;
          display: inline-block;
          margin-bottom: 14px;
          font-size: 18px;
          transition: all .15s ease; }
          .hero .header__nav ul:not(.submenu) > li .submenu a:hover {
            color: #fff; } }
    @media (min-width: 768px) {
          .hero .header__nav ul:not(.submenu) > li:hover .submenu {
            display: block; }
          .hero .header__nav ul:not(.submenu) > li:hover a:before {
            opacity: 1; } }
  .hero .header__auth {
    display: flex;
    margin-top: 6px; }
    .hero .header__auth a {
      text-decoration: none;
      border: solid 3px rgba(27, 27, 27, 0.3);
      display: inline-block;
      background-color: #e9e9e9;
      border-radius: 25px;
      text-align: center;
      color: #0d0d0d;
      width: 74px;
      padding: 5px;
      margin-right: 6px;
      font-size: 14px;
      transition: all .15s ease; }
      @media (min-width: 768px) {
        .hero .header__auth a {
          width: 100px; } }
      @media (min-width: 992px) {
        .hero .header__auth a {
          width: 190px;
          padding: 8px;
          margin-right: 16px; } }
      .hero .header__auth a:hover {
        background-color: #08cbd7;
        border-color: #08cbd7; }
  .hero__info h1 {
    color: #0d0d0d;
    font: 900 55px/1 "Gerbera", sans-serif;
    margin-top: .5em;
    position: relative;
    margin-bottom: 45px; }
    @media (min-width: 992px) {
      .hero__info h1 {
        font-size: 95px; } }
    @media (min-width: 1200px) {
      .hero__info h1 {
        margin-top: 1.65em;
        margin-left: -15px;
        font-size: 131px; } }
    .hero__info h1:after {
      position: absolute;
      content: "";
      width: 2.7em;
      height: 3px;
      background: #5f5f5f;
      bottom: -25px;
      left: -12%; }
      @media (min-width: 992px) {
        .hero__info h1:after {
          bottom: -64px; } }
  .hero-form {
    position: relative;
    z-index: 9; }
    @media (min-width: 768px) {
      .hero-form .authForm {
        display: flex; } }
    @media (min-width: 992px) {
      .hero-form {
        margin-top: 100px; } }
    @media (min-width: 1200px) {
      .hero-form {
        margin-left: 12em; } }
    .hero-form__title {
      font: 300 24px/1.25 "Gerbera", sans-serif;
      max-width: 14em;
      margin-bottom: 40px; }
      @media (min-width: 992px) {
        .hero-form__title {
          margin-left: 27px;
          font-size: 30px;
          margin-bottom: 61px; } }
    .hero-form__desc {
      font: 300 18px/1.25 "Gerbera", sans-serif;
      margin-top: 25px;
      display: inline-block;
      max-width: 20em;
      margin-bottom: 15px; }
      @media (min-width: 992px) {
        .hero-form__desc {
          margin-left: 25px;
          margin-top: 69px; } }
  .hero__img {
    padding: 0 15px;
    max-width: 26em;
    margin: 0 auto; }
    @media (min-width: 768px) {
      .hero__img {
        position: absolute;
        top: 5rem;
        right: 3rem; } }
    @media (min-width: 992px) {
      .hero__img {
        max-width: 35em; } }
    @media (min-width: 1200px) {
      .hero__img {
        top: 10.95rem;
        right: 16.55rem; } }

.steps {
  padding: 50px 0; }
  @media (min-width: 992px) {
    .steps {
      padding: 100px 0 168px; } }
  .steps-inner {
    border-radius: 10px;
    box-shadow: 0 0 76px 0 rgba(0, 0, 0, 0.12);
    background-color: #fcfcfc;
    overflow: hidden;
    max-width: 1270px;
    margin: 0 auto; }
  .steps__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 30px 15px 50px; }
    @media (min-width: 992px) {
      .steps__items {
        flex-wrap: nowrap; } }
    @media (min-width: 1200px) {
      .steps__items {
        flex-wrap: nowrap;
        padding: 30px 85px 82px; } }
  .steps__item {
    text-align: center;
    margin: 0 auto;
    width: 100%;
    margin-bottom: 50px; }
    @media (min-width: 768px) {
      .steps__item {
        width: 50%;
        padding: 0 20px;
        margin-bottom: 0; } }
    @media (min-width: 992px) {
      .steps__item {
        max-width: 250px; } }
    .steps__item:last-child .steps__numb:before {
      display: none; }
  .steps__icon {
    max-width: 44px;
    margin: 0 auto;
    min-height: 110px;
    line-height: 100px; }
  .steps__numb {
    width: 45px;
    height: 45px;
    color: #fff;
    position: relative;
    border-radius: 50%;
    margin: 0 auto;
    background: #e9e9e9;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative; }
    @media (min-width: 1200px) {
      .steps__numb:before {
        content: '';
        position: absolute;
        width: 14.3rem;
        height: 1px;
        background: #cccccc;
        left: 36px;
        z-index: 9; } }
    .steps__numb span {
      width: 27px;
      height: 27px;
      background: #465ce4;
      font: 900 18px/1 "Gerbera", sans-serif;
      display: inline-block;
      border-radius: 50%;
      z-index: 9;
      display: flex;
      justify-content: center;
      align-items: center; }
  .steps__desc {
    min-height: 75px;
    margin-bottom: 5px;
    max-width: 20em;
    margin: 0 auto; }
  .steps__footer {
    padding: 15px;
    background: #0d0d0d;
    text-align: center; }
    @media (min-width: 768px) {
      .steps__footer {
        padding: 46px; } }

.services {
  background: url("../img/services-bg.jpg");
  background-size: cover;
  padding: 25px 0 50px; }
  @media (min-width: 992px) {
    .services {
      padding: 98px 0 168px; } }
  .services h2 {
    margin-bottom: 1.26em; }
  .services-inner {
    margin-bottom: 66px; }
    @media (min-width: 768px) {
      .services-inner {
        display: flex; } }
  .services__slider-image {
    margin: 0 auto;
    max-width: 40%; }
    @media (min-width: 1200px) {
      .services__slider-image {
        max-width: 435px; } }
    .services__slider-image .slick-dots {
      bottom: -31px;
      right: 15px;
      text-align: right; }
      .services__slider-image .slick-dots li {
        width: 57px;
        height: 2px;
        padding: 2px; }
        .services__slider-image .slick-dots li button {
          background: #ccc;
          height: 2px;
          padding: 2px;
          width: 60px; }
          .services__slider-image .slick-dots li button:before {
            display: none; }
          .services__slider-image .slick-dots li button:hover {
            background: #08cbd7; }
        .services__slider-image .slick-dots li.slick-active button {
          background: #08cbd7; }
  @media (min-width: 768px) {
    .services__slider-content {
      max-width: 60%; } }
  .services .slider-content {
    padding: 0 50px; }
    @media (min-width: 768px) {
      .services .slider-content {
        margin-left: 44px; } }
    @media (min-width: 1200px) {
      .services .slider-content {
        max-width: 830px; } }
    .services .slider-content__item {
      padding-top: 8px;
      min-height: 12em;
      border-bottom: 1px solid #ccc;
      margin-top: 24px; }
      .services .slider-content__item h3 {
        font: 600 18px/1.1 "Gerbera", sans-serif;
        margin-bottom: 27px; }

.footer {
  background: #1b1b1b; }
  .footer__inner {
    padding: 150px 0 20px;
    position: relative; }
    @media (min-width: 768px) {
      .footer__inner {
        display: flex; } }
    @media (min-width: 1200px) {
      .footer__inner {
        padding: 95px 0; } }
    @media (min-width: 1600px) {
      .footer__inner {
        padding-left: 245px; } }
  .footer__nav {
    display: flex; }
    @media (min-width: 768px) {
      .footer__nav {
        display: block; } }
    @media (min-width: 1600px) {
      .footer__nav {
        display: flex; } }
    .footer__nav nav:not(:last-child) {
      margin-right: 50px; }
  .footer__item {
    border-bottom: 1px solid #343434;
    padding-bottom: 30px;
    margin-bottom: 50px;
    width: 100%; }
    @media (min-width: 768px) {
      .footer__item {
        width: auto;
        width: 25%;
        margin-right: 5%; } }
    @media (min-width: 1200px) {
      .footer__item {
        margin-right: 91px;
        min-width: 254px;
        width: inherit; }
        .footer__item:nth-child(2) {
          margin-right: 123px; }
          .footer__item:nth-child(2) .footer__nav nav:not(:last-child) {
            margin-right: 61px; } }
    .footer__item h3 {
      color: #fff;
      margin-top: 0;
      font: 600 18px/1 "Gerbera", sans-serif;
      margin-bottom: 24px; }
    .footer__item nav li a {
      color: #5f5f5f;
      font-size: 16px;
      margin-bottom: 6px;
      display: inline-block; }
  .footer__logo {
    font: 600 48px/1 "Gerbera", sans-serif;
    color: #343434;
    position: absolute;
    top: 36px;
    left: 0;
    margin: auto; }
    @media (min-width: 768px) {
      .footer__logo {
        bottom: 0;
        right: 0; } }

#sign_up {
  background: #0d0d0d;
  max-width: 728px;
  padding: 20px;
  box-shadow: none;
  width: 100%; }
  @media (min-width: 768px) {
    #sign_up {
      padding: 40px 57px 30px 67px; } }
  #sign_up h4 {
    font: 900 24px/1.25 "Gerbera", sans-serif;
    color: #5f5f5f;
    text-align: center;
    margin-bottom: 44px;
    margin-top: 0; }
    @media (min-width: 768px) {
      #sign_up h4 {
        font-size: 36px; } }
  @media (min-width: 768px) {
    #sign_up .modal__cont {
      display: flex; } }
  #sign_up .modal__desc {
    font-size: 20px;
    color: #5f5f5f;
    flex-basis: 42.5%;
    margin-top: 10px;
    line-height: 1.2;
    margin-bottom: 20px; }
    @media (min-width: 768px) {
      #sign_up .modal__desc {
        font-size: 30px; } }
  #sign_up .modal__form {
    flex-basis: 57.5%;
    margin-bottom: 34px; }
    @media (min-width: 768px) {
      #sign_up .modal__form {
        padding-left: 58px; } }
    #sign_up .modal__form input,
    #sign_up .modal__form input:focus {
      background: #0d0d0d;
      border-color: #474747;
      color: #fff; }
  #sign_up .modal__login {
    margin-top: 22px;
    font-size: 16px;
    max-width: 100px;
    text-align: right; }
  #sign_up .modal__footer {
    display: flex;
    justify-content: flex-end; }
    #sign_up .modal__footer span {
      font-size: 14px;
      text-align: center; }
      @media (min-width: 768px) {
        #sign_up .modal__footer span {
          max-width: 50%; } }
      #sign_up .modal__footer span a {
        color: #5f5f5f; }

.arrow {
  width: 30px;
  height: 30px;
  border: 1px solid #5f5f5f;
  border-left: 0;
  border-top: 0;
  display: block;
  cursor: pointer;
  transition: all .15s ease; }
  .arrow:hover {
    border-color: #08cbd7; }

.arrow-sm {
  width: 12px;
  height: 12px;
  border: 2px solid #5f5f5f;
  border-left: 0;
  border-top: 0;
  display: block;
  cursor: pointer;
  transition: all .15s ease; }

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

.carousel-prev.slick-arrow {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0;
  bottom: 15px;
  margin: auto;
  left: 0; }

.carousel-next.slick-arrow {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0;
  bottom: 15px;
  margin: auto;
  right: 0; }

.burger {
  position: absolute;
  top: 28px;
  right: 18px;
  width: 24px;
  height: 17px;
  cursor: pointer;
  z-index: 19; }
  @media (min-width: 768px) {
    .burger {
      display: none; } }

.burger span,
.burger span:before,
.burger span:after {
  width: 100%;
  position: absolute;
  height: 3px;
  background: #465ce4;
  display: block;
  transition: .3s; }

.burger span:before {
  content: "";
  top: -6px;
  transition: top 150ms 150ms, transform 150ms cubic-bezier(0.23, 1, 0.32, 1); }

.burger span {
  top: 6px; }

.burger span:after {
  content: "";
  bottom: -6px;
  transition: bottom 150ms 150ms, transform 150ms cubic-bezier(0.23, 1, 0.32, 1); }

.burger span.active:before {
  content: "";
  top: 0;
  transform: rotate(45deg);
  transition: top 150ms, transform 150ms 150ms cubic-bezier(0.68, -0.55, 0.265, 1.55); }

.burger span.active:after {
  content: "";
  bottom: 0;
  transform: rotate(-45deg);
  transition: bottom 150ms, transform 150ms 150ms cubic-bezier(0.68, -0.55, 0.265, 1.55); }

.burger span.active {
  background: rgba(255, 255, 255, 0); }

.slick-slide {
  margin: 0 10px; }

/* the parent */
.slick-list {
  margin: 0 -10px; }

@keyframes fade {
  0% {
    opacity: 0.4; }
  100% {
    opacity: 1; } }

@keyframes fade-scale {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.7);
    -moz-transform: scale(0.7);
    -ms-transform: scale(0.7);
    transform: scale(0.7); }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1); } }
