
.hero input {
    border-radius: 31.5px;
    border: solid 3px #c4c4c4;
    background-color: #e9e9e9;
    padding: 0 28px;
    font-size: 16px;
    color: $gray;
    height: 45px;
    width: 250px;
    margin-bottom: 10px;
    @include md{
      height: 63px;
      width: 269px;
      margin-right: 9px;
      font-size: 20px;
    }
  }

.modal input {
    height: 60px;
    border-radius: 31.5px;
    border: 3px solid #c4c4c4;
    background-color: #e9e9e9;
    padding: 0 28px;
    font-size: 20px;
    margin-right: 9px;
    color: #5f5f5f;
    width: 100%;
    margin-bottom: 17px;
}

.authForm__inp {
  position: relative;
  margin-bottom: 15px;
}

label.error {
  position: absolute;
  bottom: -7px;
  left: 30px;
  font-size: 13px;
  color: red;
}

input.error{
  border-color: red!important;
}
