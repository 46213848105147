.container {
  max-width: 1630px;
  margin: 0 auto;
  padding: 0 15px;
}

.hero {
  padding: 14px 0;
  background: url("../img/hero-bg.jpg");
  background-size: cover;

  @include md {
    min-height: 32em;
    padding-bottom: 100px;

  }

  @include lg{
    padding-bottom: 150px;
  }

  @include xxl{
    padding-bottom: 285px;
  }

  .header {
    &-inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 40px;

      @include md{
        padding-right: 0;
      }
    }

    &__logo {
      position: relative;
      z-index: 22;

      @include xxl{
        margin-right: 190px;
      }

      span {
        font: 600 25px/1 $gerbera;
        color: $blue;

        @include md{
          font-size: 48px;
        }
      }
    }

    &__nav {
      align-items: center;
      display: none;

      @include xl{
        margin-right: 88px;
        margin-top: 6px;
      }

      .submenu-icon{
        @include md{
          display: none;
        }
      }

      &.active{
        position: fixed;
        height: 100%;
        width: 100%;
        background: #000;
        z-index: 15;
        top: 0;
        left: 0;
        display: block;
        padding-top: 75px;
        padding-left: 15px;

        ul:not(.submenu) {
          overflow-y: auto;
          max-height: 100%;
          > li {
            display: block;
            width: calc(100% - 15px);
            border-bottom: 1px solid $darkgray;
            padding: 18px 0;
            position: relative;

            &:last-child{
              border-bottom: none;
            }

            >a{
              color: #fff;
              font-size: 24px;
              
              .submenu-icon{
                position: absolute;
                width: 20px;
                height: 20px;
                right: 5px;
                top: 25px;
                transition: all .15s ease-in-out;
          
                &.active{
                  transform: rotate(180deg);
                }
                i{
                  margin-left: 4px;
                  border-color: #fff;
                }
              }
            }
            .submenu {
              display: none;
              &.active{
                display: block;
                margin-top: 15px;
                a{
                  color: $gray;
                  display: inline-block;
                  padding: 8px 0;
                  &:hover{
                    color: #fff;
                  }
                }
              }
            }
          }
        }
      }

      @include md{
        display: flex;

        ul:not(.submenu) {
          >li {
            position: relative;
            padding: 18px 0 22px 0;
            display: inline-block;
            >a {
              font: 300 20px/1 $gerbera;
              color: $darkgray;
              margin-right: 15px;
              padding-bottom: 5px;
              letter-spacing: .5px;
  
              @include md{
                position: relative;
              }
  
              @include xl{
                margin-right: 38px;
                font-size: 24px;
              }
  
              &:before {
                content: '';
                position: absolute;
                width: 100%;
                height: 3px;
                background: $lightblue;
                bottom: 0;
                left: 0;
                transition: all .15s ease;
                opacity: 0;
              }
  
              &:hover {
                &:before {
                  opacity: 1;
                }
              }
            }
  
            @include md{
              .submenu {
                display: none;
                position: absolute;
                background: #000;
                padding: 18px 20px 8px;
                border-radius: 5px;
                top: 100%;
                min-width: 195px;
                z-index: 19;
    
                a {
                  color: $gray;
                  display: inline-block;
                  margin-bottom: 14px;
                  font-size: 18px;
                  transition: all .15s ease;
    
                  &:hover {
                    color: #fff;
                  }
                }
              }
            }
  
        
  
            &:hover {
  
              .submenu {
                display: block;
              }
  
              a {
                &:before {
                  opacity: 1;
                }
              }
            }
          }
        }
      }

      
    }

    &__auth {
      display: flex;
      margin-top: 6px;

      a {
        text-decoration: none;
        border: solid 3px rgba(27, 27, 27, .3);
        display: inline-block;
        background-color: #e9e9e9;
        border-radius: 25px;

        text-align: center;
        color: $black;
      
        width: 74px;
        padding: 5px;
        margin-right: 6px;
        font-size: 14px;
        transition: all .15s ease;


        @include md{
          width: 100px;
        }

        @include lg{
          width: 190px;
          padding: 8px;
          margin-right: 16px;
        }

        &:hover {
          background-color: $lightblue;
          border-color: $lightblue;
        }
      }
    }
  }

  &__info {
    h1 {
      color: $black;
      font: 900 55px/1 $gerbera;
      margin-top: .5em;
      position: relative;
      margin-bottom: 45px;

      @include lg{
        font-size: 95px;
      }

      @include xl{
        margin-top: 1.65em;
        margin-left: -15px;
        font-size: 131px;
      }

      &:after {
        position: absolute;
        content: "";
        width: 2.7em;
        height: 3px;
        background: $gray;
        bottom: -25px;
        left: -12%;

        @include lg{
          bottom: -64px;
        }
      }
    }
  }

  &-form {
    position: relative;
    z-index: 9;

    @include md{
      .authForm{
        display: flex;
      }
    }

    @include lg{
      margin-top: 100px;
    }

    @include xl{
      margin-left: 12em;
    }

    &__title {
      font: 300 24px/1.25 $gerbera;
      max-width: 14em;
      margin-bottom: 40px;

      @include lg{
        margin-left: 27px;
        font-size: 30px;
        margin-bottom: 61px;
      }
    }

    &__desc {
      font: 300 18px/1.25 $gerbera;
      margin-top: 25px;
      display: inline-block;
      max-width: 20em;
      margin-bottom: 15px;

      @include lg{
        margin-left: 25px;
        margin-top: 69px;

      }
    }
  }

  &__img {
    padding: 0 15px;
    max-width: 26em;
    margin: 0 auto;

    @include md{
      position: absolute;
      top: 5rem;
      right: 3rem;
    }
    @include lg{
      max-width: 35em;
    }

    @include xl{
      top: 10.95rem;
      right: 16.55rem;
    }
  }
}

.steps {
  padding: 50px 0;
  @include lg{
    padding: 100px 0 168px;
  }
  &-inner {
    border-radius: 10px;
    box-shadow: 0 0 76px 0 rgba(0, 0, 0, 0.12);
    background-color: #fcfcfc;
    overflow: hidden;

    max-width: 1270px;
    margin: 0 auto;
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 30px 15px 50px;

    @include lg{
      flex-wrap: nowrap;
    }

    @include xl{
      flex-wrap: nowrap;
      padding: 30px 85px 82px;
    }

  }

  &__item {
    text-align: center;
    margin: 0 auto;
    width: 100%;
    margin-bottom: 50px;

    @include md{
      width: 50%;
      padding: 0 20px;
      margin-bottom: 0;
    }

    @include lg{
      max-width: 250px;
    }

    &:last-child {
      .steps__numb {
        &:before {
          display: none;
        }
      }
    }
  }

  &__icon {
    max-width: 44px;
    margin: 0 auto;
    min-height: 110px;
    line-height: 100px;
  }

  &__numb {
    width: 45px;
    height: 45px;
    color: #fff;
    position: relative;
    border-radius: 50%;
    margin: 0 auto;
    background: #e9e9e9;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;


    @include xl{
      &:before {
        content: '';
        position: absolute;
        width: 14.3rem;
        height: 1px;
        background: #cccccc;
        left: 36px;
        z-index: 9;
      }
    }

    span {
      width: 27px;
      height: 27px;
      background: $blue;
      font: 900 18px/1 $gerbera;
      display: inline-block;
      border-radius: 50%;
      z-index: 9;
      display: flex;
      justify-content: center;
      align-items: center;

    }
  }

  &__desc {
    min-height: 75px;
    margin-bottom: 5px;
    max-width: 20em;
margin: 0 auto;
  }

  &__footer {
    padding: 15px;
    background: $black;
    text-align: center;
    @include md{
      padding: 46px;
    }
  }
}

.services {
  background: url("../img/services-bg.jpg");
  background-size: cover;
  padding: 25px 0 50px;

  @include lg{
    padding: 98px 0 168px;
  }

  h2 {
    margin-bottom: 1.26em;
  }

  &-inner {
    margin-bottom: 66px;
    @include md{
      display: flex;
    }
  }

  &__slider-image {

      margin: 0 auto;
      max-width: 40%;

      @include xl{
        max-width: 435px;
      }


    .slick-dots {
      bottom: -31px;
      right: 15px;
      text-align: right;

      li {
        width: 57px;
        height: 2px;
        padding: 2px;

        button {
          background: #ccc;
          height: 2px;
          padding: 2px;
          width: 60px;

          &:before {
            display: none;
          }

          &:hover {
            background: $lightblue;
          }
        }

        &.slick-active {
          button {
            background: $lightblue;
          }
        }
      }
    }
  }

  &__slider-content{
    @include md{
      max-width: 60%;
    }
  }

  .slider-content {
    padding: 0 50px;

    @include md{
      margin-left: 44px;
    }

    @include xl{
      max-width: 830px;
    }

    &__item {
      padding-top: 8px;
      min-height: 12em;
      border-bottom: 1px solid #ccc;
      margin-top: 24px;

      h3 {
        font: 600 18px/1.1 $gerbera;
        margin-bottom: 27px;
      }
    }
  }
}

.footer {
  background: #1b1b1b;

  &__inner {
    padding: 150px 0 20px;
    position: relative;

    @include md{
      display: flex;

    }
    @include xl{
      padding: 95px 0;
    }

    @include xxl{
      padding-left: 245px;
    }
  }

  &__nav {
    display: flex;

    @include md{
      display: block;
    }

    @include xxl{
      display: flex;
    }
    

    nav:not(:last-child) {
      margin-right: 50px;
    }
  }

  &__item {

    border-bottom: 1px solid #343434;
    padding-bottom: 30px;
    margin-bottom: 50px;
    width: 100%;

    @include md{
      width: auto;
      width: 25%;
      margin-right: 5%;
    }

@include xl{
  margin-right: 91px;
  min-width: 254px;
  width: inherit;

  &:nth-child(2) {
    margin-right: 123px;

    .footer__nav {
      nav:not(:last-child) {
        margin-right: 61px;
      }
    }
  }
}


    h3 {
      color: #fff;
      margin-top: 0;
      font: 600 18px/1 $gerbera;
      margin-bottom: 24px;
    }

    nav {
      li {
        a {
          color: $gray;
          font-size: 16px;
          margin-bottom: 6px;
          display: inline-block;
        }
      }
    }
  }

  &__logo {
    font: 600 48px/1 $gerbera;
    color: #343434;
    position: absolute;
    top: 36px;
    left: 0;
    margin: auto;
    @include md{
      bottom: 0;
      right: 0;
    }
  }
}

#sign_up {
  background: $black;
  max-width: 728px;
  padding: 20px;
  box-shadow: none;
  width: 100%;
  @include md{
    padding: 40px 57px 30px 67px;
  }

  h4 {
    font: 900 24px/1.25 $gerbera;
    color: $gray;
    text-align: center;
    margin-bottom: 44px;
    margin-top: 0;

    @include md{
      font-size: 36px;
    }
  }

  .modal {

    @include md{
      &__cont {
        display: flex;
      }
    }

    &__desc {
      font-size: 20px;
      color: #5f5f5f;
      flex-basis: 42.5%;
      margin-top: 10px;
      line-height: 1.2;
      margin-bottom: 20px;

      @include md{
        font-size: 30px;
      }
    }

    &__form {
      flex-basis: 57.5%;
      margin-bottom: 34px;

      @include md{
        padding-left: 58px;
      }

      input,
      input:focus {
        background: $black;
        border-color: $darkgray;
        color: #fff;
      }
    }

    &__login {
      margin-top: 22px;
      font-size: 16px;
      max-width: 100px;
      text-align: right;
  }

    &__footer {
      display: flex;
      justify-content: flex-end;

      span {
        font-size: 14px;
        text-align: center;
        @include md{
          max-width: 50%;
        }

        a {
          color: $gray;
        }
      }
    }
  }
}


.arrow {
  width: 30px;
  height: 30px;
  border: 1px solid $gray;
  border-left: 0;
  border-top: 0;
  display: block;
  cursor: pointer;
  transition: all .15s ease;

  &:hover {
    border-color: $lightblue;
  }
}


.arrow-sm {
  width: 12px;
  height: 12px;
  border: 2px solid $gray;
  border-left: 0;
  border-top: 0;
  display: block;
  cursor: pointer;
  transition: all .15s ease;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.carousel-prev.slick-arrow {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0;
  bottom: 15px;
  margin: auto;
  left: 0;
}

.carousel-next.slick-arrow {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0;
  bottom: 15px;
  margin: auto;
  right: 0;
}


.burger {
  position: absolute;
  top: 28px;
  right: 18px;
  width: 24px;
  height: 17px;
  cursor: pointer;
  z-index: 19;

  @include md {
    display: none;
  }
}

.burger span,
.burger span:before,
.burger span:after {
  width: 100%;
  position: absolute;
  height: 3px;
  background: $blue;
  display: block;
  transition: .3s;
}

.burger span:before {
  content: "";
  top: -6px;
  transition: top 150ms 150ms, transform 150ms cubic-bezier(0.23, 1, 0.32, 1);
}

.burger span {
  top: 6px;
}

.burger span:after {
  content: "";
  bottom: -6px;
  transition: bottom 150ms 150ms, transform 150ms cubic-bezier(0.23, 1, 0.32, 1);
}

.burger span.active:before {
  content: "";
  top: 0;
  transform: rotate(45deg);
  transition: top 150ms, transform 150ms 150ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.burger span.active:after {
  content: "";
  bottom: 0;
  transform: rotate(-45deg);
  transition: bottom 150ms, transform 150ms 150ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.burger span.active {
  background: rgba(255, 255, 255, 0);
}

.slick-slide {
  margin: 0 10px;
}

/* the parent */
.slick-list {
  margin: 0 -10px;
}