.btn {
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
  border: none;
  outline: none;
  cursor: pointer;
  letter-spacing: 0.7px;
  color: $black;
  font-family: $gerbera;
  border-radius: 32px;
  transition: all .15s ease;
  font-size: 20px;
  font-weight: 900;
  height: 45px;
  line-height: 40px;
  display: inline-block;
  padding: 0 15px;
  min-width: 250px;

  @include md{
    min-width: 285px;
    height: 64px;
    line-height: 60px;
    font-size: 24px;

  }

  &-lg{
    @include lg{
      min-width: 350px;
    }
  }

  &-primary {
    background: $lightblue;

    &:hover {
      background: lighten($lightblue, 5%);
    }
  }
}
