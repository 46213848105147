// Цвета
$red: #e0004d;
$black: #0d0d0d;
$blue: #465ce4;
$lightblue: #08cbd7;
$gray: #5f5f5f;
$darkgray: #474747;

// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1200px;

$screen-xxl-min: 1600px;


// Small devices
@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
      @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$screen-md-min}) {
      @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
      @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
      @content;
  }
}

// Extra extra large devices
@mixin xxl {
  @media (min-width: #{$screen-xxl-min}) {
      @content;
  }
}